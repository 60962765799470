import { getMembershipProperty } from '__vuex__/helpers';
import { DEVICE_TYPES }          from '__application-config__';

export default {
  userId: state => state.user.id,
  userUsername: state => state.user.username,
  userEmail: state => state.user.email,
  isUserAuthenticated: state => !!state.user.authenticated,

  language: (state, getters, rootState, rootGetters) => rootState.language,
  membershipDescription: (state) => state.membership?.membership_offer?.membership?.description ?? '',
  membershipIdentifier: (state) => state.membership?.membership_offer?.membership?.identifier ?? '',
  isAllowedGroupsEdit: (state) => !!(getMembershipProperty(state, 'groups_edit')) ?? false,
  isMembershipFree: (state, getters) => getters.membershipIdentifier === 'free',
  isMembershipBasic: (state, getters) => getters.membershipIdentifier === 'basic',
  isMembershipPremium: (state, getters) => getters.membershipIdentifier === 'premium',

  /**
   * @param {Object.<string, any>} state
   * @returns {boolean}
   */
  isMobileDevice: (state) => {
    return state.deviceType === DEVICE_TYPES.MOBILE;
  },

  /**
   * @param {Object.<string, any>} state
   * @returns {boolean}
   */
  isDesktopDevice: (state) => {
    return state.deviceType === DEVICE_TYPES.DESKTOP;
  },

  /**
   * @param {Object.<string, any>} state
   * @returns {boolean}
   */
  isTabletDevice: (state) => {
    return state.deviceType === DEVICE_TYPES.TABLET;
  }
};
