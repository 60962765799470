import state          from './_state';
import getters        from './_getters';
import mutations      from './_mutations';
import actions        from './_actions';
import * as MUTATIONS from './_mutation-types';
import * as ACTIONS   from './_action-types.js';

export const VUEX_TILES = {
	NAMESPACE: 'VUEX_TILES',
	MUTATIONS,
	ACTIONS,
	MODULE_DEFINITION: {
		namespaced: true,
		state,
		getters,
		mutations,
		actions
	}
};
