import { Cookies } from '__application-utils__';

export enum URLS {
	TILES = 'map_api:tiles',
	MAP_TILE = 'map_api:map_tile',
	FILTER_URL = 'map_api:filter_url',
	TILE_RANK = 'map_api:tile_rank',
	TILE_TAG = 'map_api:tile_by_tag',
	TILE_PERSON = 'map_api:tiles_person'
}

export enum SEARCH_TYPES {
	HOMEPAGE = 'homepage',
	SEARCH = 'search'
}

export enum ORDER_OPTIONS_IDENTIFIERS {
	RANK = 'rank',
	LATEST = 'latest',
	ALPHABET_A_Z = 'alphabet_a_z',
	ALPHABET_Z_A = 'alphabet_z_a',
	DIFFICULTY_1_6 = 'difficulty_1_6',
	DIFFICULTY_6_1 = 'difficulty_6_1'
}

export function createOrderOptions(): Array<{ id: string; label: string }> {
	return [
		{
			id: ORDER_OPTIONS_IDENTIFIERS.RANK,
			label: this.$t('rank')
		},
		{
			id: ORDER_OPTIONS_IDENTIFIERS.LATEST,
			label: this.$t('date of creation')
		},
		{
			id: ORDER_OPTIONS_IDENTIFIERS.ALPHABET_A_Z,
			label: this.$t('alphabetically (a-z)')
		},
		{
			id: ORDER_OPTIONS_IDENTIFIERS.ALPHABET_Z_A,
			label: this.$t('alphabetically (z-a)')
		},
		{
			id: ORDER_OPTIONS_IDENTIFIERS.DIFFICULTY_1_6,
			label: this.$t('difficulty (1-6)')
		},
		{
			id: ORDER_OPTIONS_IDENTIFIERS.DIFFICULTY_6_1,
			label: this.$t('difficulty (6-1)')
		}
	];
}

const SEARCH_QUERY_KEY = 'searchQuery';

export class SearchQueryCookie {

	public static get() {
		return Cookies.get(SEARCH_QUERY_KEY);
	}

	public static set(searchQuery) {
		return Cookies.set(SEARCH_QUERY_KEY, searchQuery);
	}

	public static has() {
		return Cookies.has(SEARCH_QUERY_KEY);
	}

	public static delete() {
		return Cookies.delete(SEARCH_QUERY_KEY);
	}
}
