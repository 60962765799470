import URLS from './URLS';
import axios from '__js__/axios';
import reverse from 'django-reverse';
import { AxiosResponse } from 'axios';
import { vm } from './../../../../';

interface IData {
	mapTranslateId: string
}

interface IResponseData {
	[key: string]: any
}

export default async ({ mapTranslateId }: IData): Promise<AxiosResponse<IResponseData>> => {
	return await axios.put(reverse(URLS.CLONE, vm.$store.state.language, mapTranslateId));
}
