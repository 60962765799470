export function getDPI(): number {
  const box = window.document.createElement('div');

  box.style.cssText = `
		width: 1in;
		height: 1in;
		box-sizing: "border-box",
		position: relative;
		visibility: hidden;
		z-index: -1;
	`;

  window.document.body.appendChild(box);
  const dpi = box.offsetHeight;
  window.document.body.removeChild(box);

  return dpi;
}
