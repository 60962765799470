import Vue              from 'vue';
import * as EVENT_TYPES from './event-types';

Object.defineProperty(Vue.prototype, '$EventType', {
	enumerable: false,
	writable: false,
	configurable: false,
	value: EVENT_TYPES
});

const EVENT_BUS = new Vue({});

export const EVENT_BUS_TYPES = EVENT_TYPES;
export { EVENT_BUS };

export default EVENT_BUS;
