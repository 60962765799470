export const APPLICATION_ROOT_ELEMENT_ID = "vue-app";

export const MODAL_TYPES = {
  LOGIN: "LOGIN",
  PROMPT: "PROMPT",
  PROMPT_INPUT: "PROMPT_INPUT",
  FUNCTION_TIP: "FUNCTION_TIP",
  COMMENT_FOR_AUTHOR: "COMMENT_FOR_AUTHOR",
  EXERCISE: "EXERCISE",
  PAYMENT_REQUIRED: "PAYMENT_REQUIRED",
  EXERCISE_START: "EXERCISE_START",
  EXERCISE_RESULTS: "EXERCISE_RESULTS",
  EXERCISE_BASE_MODAL: "EXERCISE_BASE_MODAL"
};

export const BROWSER_TYPES = {
  OPERA: "opera",
  CHROME: "chrome",
  SAFARI: "safari",
  FIREFOX: "firefox",
  IE: "ie"
};

export const DEVICE_TYPES = {
  MOBILE: "mobile",
  TABLET: "tablet",
  DESKTOP: "desktop"
};

// milliseconds * seconds * minutes * hours - default cookie expiration 2 hours
export const COOKIE_EXPIRATION = 1000 * 60 * 60 * 2;

export const DEFAULT_MAP_TILE_IMAGE = "/static/images/map/default-map-tile.png";
