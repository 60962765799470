import Vue              from './vue';
import * as Application from './Application';

// Template to process Vue renderer
const template = document.getElementById('app');
// Remove all script elements from template, and append them to the body element
document.body.append(...Array.from(template.getElementsByTagName('script')));

Vue.config.devtools = process.env.NODE_ENV !== 'production';
Vue.config.silent = process.env.NODE_ENV !== 'production';
Vue.config.performance = process.env.NODE_ENV !== 'production';

const { vm, store, i18n } = Application.create(
  {
    el: '#app',
    template
  }
);

if ( process.env.NODE_ENV === 'development' ) {
  window.$vm = vm;
}

export {
  vm, store, i18n
};
