import axios           from 'axios';
import { MODAL_TYPES } from '__application-config__';

export default {

  methods: {
    /**
     * @param {Error} error
     **/
    handleError(error) {
      // if ( axios.isAxiosError(error) || axios.isCancel(error) ) {
      //   // handleAxiosError(error);
      //   return;
      // }

      if (
        ['ECONNABORTED'].includes(error.code) ||
        ['Request aborted'].includes(error.message) ||
        axios.isCancel(error)
      ) {
        return;
      }

      const toast = {
        display: true,
        title: this.$t('Unexpected error'),
        message: this.$t('Something went wrong.')
      };

      switch ( error?.response?.status ) {
        case 500:
          break;

        case 400:
          toast.title = this.$t('Validation error');

          if ( Array.isArray(error.response.data) ) {
            toast.message = error.response.data.join(',');
          } else {
            Object.keys(error.response.data).forEach(key => {
              toast.title = key;
              let elementVal = error.response.data[key];
              toast.message = Array.isArray(elementVal) ? elementVal.join(',') : elementVal;
            });
          }
          break;

        case 401:
          toast.display = false;
          const t = this.$modal;
          if ( !this.$modal.isOpenByType(MODAL_TYPES.LOGIN) )
            this.$modal.show(MODAL_TYPES.LOGIN);
          break;

        case 402:
          toast.display = false;
          if ( !this.$modal.isOpenByType(MODAL_TYPES.PAYMENT_REQUIRED) )
            this.$modal.show(
              MODAL_TYPES.PAYMENT_REQUIRED,
              {
                propsData: {
                  feature_name: error.response.data.feature_name,
                  messages: error.response.data.messages,
                  suggested_membership_offers: error.response.data.suggested_membership_offers
                }
              }
            );
          break;

        case 404:
          toast.title = this.$t('Not found');
          if ( error.response.data.detail ) {
            toast.message = error.response.data.detail;
          }
          break;

        default:
          // toast.title = this.$t('Unexpected error');
          // toast.message = error.toString();
          break;
      }

      if ( toast.display ) {
        this.$toast.error({ title: toast.title, message: toast.message });
      }

    }
  }
};

/**
 * @param {import('axios').AxiosError} axiosError
 */
function handleAxiosError(axiosError) {
  if ( axios.isCancel(axiosError) ) {
    console.group('Canceled AxiosRequest');
    console.log(axiosError);
    console.groupEnd();
    return;
  }
}
