/**
 * @param {Object} state
 * @returns {*[]}
 */
const getMembershipProperties = (state) => state.membership?.properties ?? [];

/**
 * @param {Object} state
 * @param {string} propName
 * @returns {*}
 */
export const getMembershipProperty = (state, propName) =>
	getMembershipProperties(state)
		.find(({ type_property }) => type_property.identifier === propName);
