/**
 * https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-vue-app-with-vue-i18n
 * https://kazupon.github.io/vue-i18n/
 */

import Vue from '__vue__/vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const options = { language: 'cz' };

const loadLocalMessages = () => {
	const locales = require.context('./../../../translations', true, /[A-Za-z0-9-_,\s]+\.json$/i);
	const messages = {};

	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)/i);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(key);
		}
	});

	return messages;
};

export const create = ({ language } = options) => {
	return new VueI18n({
		locale: language,
		fallbackLocale: 'en',
		missing: (locale, key, vm) => key,
		messages: loadLocalMessages()
	});
};
