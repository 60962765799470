(function(window) {
	if (!window) return;

	const addEventListener = EventTarget.prototype.addEventListener;
	const removeEventListener = EventTarget.prototype.removeEventListener;

	/**
	 * @param {string} type
	 * @param {EventListenerOrEventListenerObject|null} listener
	 * @param {boolean|AddEventListenerOptions|undefined} [options]
	 */
	window.EventTarget.prototype.addEventListener = function(type, listener, options) {
		const _listener = listener?.handleEvent || listener;

		if (!this._$listeners) this._$listeners = {};
		if (!this._$listeners[type]) this._$listeners[type] = [];

		if (!this._$listeners[type].find((item) => item.listener === _listener))
			this._$listeners[type].push({
				type,
				listener: _listener
				// once: !!(options?.once),
				// passive: !!(options?.passive),
				// capture: !!(options?.capture)
			});

		return addEventListener.apply(this, arguments);
	};

	/**
	 * @param {string} type
	 * @param {EventListenerOrEventListenerObject} callback
	 * @param {EventListenerOptions} [options]
	 */
	window.EventTarget.prototype.removeEventListener = function(type, callback, options) {
		const listener = callback?.handleEvent || callback;

		if (this._$listeners && this._$listeners[type]?.length) {
			const listenersOfType = this._$listeners[type];
			const index = listenersOfType.findIndex(item => item.listener === listener);

			if (index > -1) listenersOfType.splice(index, 1);
			if (!listenersOfType.length) delete this._$listeners[type];
			if (!Object.keys(this._$listeners).length) delete this._$listeners;
		}

		return removeEventListener.apply(this, arguments);
	};


	/**
	 * @param {string|undefined} [type]
	 * @return {{}|undefined|*}
	 */
	window.EventTarget.prototype.$getEventListeners = function(type) {
		if (!this._$listeners) return undefined;
		else if (typeof type === 'undefined') return this._$listeners;
		else if (this._$listeners[type]?.length) return this._$listeners[type];
		else return undefined;
	};
})(window);
