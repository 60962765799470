import * as mt from './_mutation-types';

export default {
	/**
	 * @param state
	 * @param {string} language
	 * @returns {*}
	 */
	[mt.LANGUAGE_SET]: (state, language) => state.language = language,
	[mt.MEMBERSHIP_SET]: (state, membership) => state.membership = membership
};
