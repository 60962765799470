import Vue             from "./../../vue";
import uuid            from "uuid/v1";
import { MODAL_TYPES } from "__application-config__";

/**
 * OPTIONS
 * {
 * 		identifier: String
 *  	propsData: { closeHandlerFn, ... }
 * }
 */


class ModalController {
  static install(Vue, options = {}) {
    const store               = [],
          /**
           * @type {Map<string,Set>}
           */
          storeEventListeners = new Map([
            ["hide", new Set()],
            ["open", new Set()]
          ]);

    Vue.prototype.$modal = {

      /**
       * @param {string} type
       * @param {Object} [options]
       * @returns {Promise<string|null>}
       */
      async show(type, options = {}) {
        const componentDefinition = await getComponentDefinition(type);
        if ( !componentDefinition ) {
          console.warn(`WARNING: the modal ${ type } is not defined ...`);
          return null;
        }

        const identifier = options.identifier || `modal-${ uuid() }`;
        const { propsData = {} } = options;
        const { modalProps = {} } = propsData;

        const Component = Vue.extend(componentDefinition);
        const component = new Component({
          propsData: {
            ...propsData,
            modalProps: {
              ...propsData.modalProps,
              visible: true,
              closeHandlerFn: ({ id }) => {
                this.$modal.hide({ id });
                const { closeHandlerFn } = modalProps;
                closeHandlerFn && closeHandlerFn();
              }
            }
          },
          parent: this
        });

        component.$mount();
        component.$el.id = identifier;
        if ( options?.classList ) {
          const classList = typeof options.classList === "string" ?
            options.classList.split(/\s/g) :
            options.classList;

          component.$el.classList.add(...classList);
        }

        this.$root.$el.appendChild(component.$el);

        store.push({ component, identifier, type });
        storeEventListeners.get("open").forEach(fn => fn({ component, identifier, type }));

        return identifier;
      },

      hide({ id }) {
        const index = store.findIndex(({ identifier }) => identifier === id);
        if ( index === -1 ) return false;

        const item = store[index];
        const { component } = item;

        component.visible = false;
        store.splice(index, 1);
        window.setTimeout(() => component.$parent.$el.removeChild(component.$el), 201);
        storeEventListeners.get("hide").forEach(fn => fn(item));

        return true;
      },

      /**
       * @returns {number}
       */
      size() {
        return store.length;
      },

      /**
       * @param {string} identifier
       * @returns {boolean}
       */
      isOpenById(identifier) {
        return !!store.find(({ identifier: id }) => id === identifier);
      },

      /**
       * @param {string} type
       * @returns {boolean}
       */
      isOpenByType(type) {
        return !!store.find(({ type }) => type === type);
      },

      /**
       * @param {function(item: { component, type, identifier}, index:number): { component, type, identifier} | undefined } fn
       */
      find(fn) {
        store.find(({ component, type, identifier }, index) => fn({ component, type, identifier }, index));
      },

      /**
       * @returns {void}
       */
      hideAll() {
        store.forEach(({ identifier }) => {
          this.$modal.hide({ id: identifier });
        });
      },

      /**
       * @param {Function} fn
       * @param {string} type
       * @returns {void}
       */
      addEventListener(type, fn) {
        storeEventListeners.get(type).add(fn);
      },

      /**
       * @param {Function} fn
       * @param {string} type
       * @returns {void}
       */
      removeEventListener(type, fn) {
        storeEventListeners.get(type).delete(fn);
      }
    };
  }
}

async function getComponentDefinition(modalType) {
  let module = null;

  switch ( modalType ) {
    case MODAL_TYPES.LOGIN:
      module = await import("__components__/popup/Login/App");
      break;
    case MODAL_TYPES.FUNCTION_TIP:
      module = await import("__components__/popup/FunctionTip/App");
      break;
    case MODAL_TYPES.COMMENT_FOR_AUTHOR:
      module = await import("__components__/popup/CommentForAuthor/App");
      break;
    case MODAL_TYPES.PROMPT:
      module = await import("__components__/popup/Prompt/App");
      break;
    case MODAL_TYPES.PROMPT_INPUT:
      module = await import("__components__/popup/PromptInput/App");
      break;
    case MODAL_TYPES.EXERCISE:
      module = await import("__components__/Exercise/components/Exercise/ExerciseModal/ExerciseModal");
      break;
    case MODAL_TYPES.PAYMENT_REQUIRED:
      module = await import("__components__/membership/ModalPaymentRequired");
      break;
    case MODAL_TYPES.EXERCISE_BASE_MODAL:
      module = await import('__components__/Exercise/components/ExerciseAttempt/components/Modal/ExerciseModal');
      break;
    case MODAL_TYPES.EXERCISE_RESULTS:
      module = await import("__components__/Exercise/components/Exercise/ExerciseList/components/ExerciseResultsModal");
  }

  return module && module.default ?
    module.default :
    module;
}

Vue.use(ModalController);
