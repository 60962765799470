export default {
  /**
   * @param {Object.<string, unknown>} _state
   * @param {Object.<string, unknown>} getters
   * @return {boolean}
   */
  isNextUrl(_state, getters) {
    return !!getters.nextUrl;
  },
  /** @returns {string|null} */
  nextUrl(state) {
    const { nextUrl } = state;

    return (typeof nextUrl === 'string' && nextUrl.length) ?
      nextUrl :
      null;
  },
  /** @returns {Object.<string, any>} */
  constraints(state) {
    const { filters, order } = state;

    return {
      order: order.value,
      tag: filters.tag.value,
      type: filters.type.value,
      person: filters.person.value,
      language: filters.language.value,
      difficulty: filters.difficulty.value,
      status: filters.status.value,
      searchQuery: filters.searchQuery.value
    };
  },
  /**
   * @param {Object.<string, any>} state
   * @returns {any[]}
   */
  tiles(state) {
    return state.tiles.map(tile => ({
      id: tile.id,
      img_small: tile.img_small,
      rankAvg: tile.avg_rank,
      rankCount: tile.count_rank,
      cardCount: tile.count_map_card,
      hasExercise: tile.has_exercice,
      rankMy: tile.my_rank,
      title: tile.map_translates?.[0].name ?? '',
      description: tile.map_translates?.[0].description ?? '',
      slug: tile.map_translates?.[0].slug ?? '',
      status: tile.map_translates?.[0].status ?? '',
      detailUrl: tile.map_translates?.[0].detail_url ?? '',
      owner: tile.owner,
      authors: tile.authors,
      difficulty: tile.difficulty,
      mapTranslates: tile.map_translates,
      allMapTranslates: tile.all_map_translates,
      accessGroup: tile.groups_with_access?.[0] ?? null
    }));
  }
};
