import * as mt from "./_mutation-types";

export default {
  [mt.TILE_SET]: (state, tile) => state.tile = tile,
  [mt.TILE_DIFFICULTY_SET]: (state, difficulty) => state.tile.difficulty = difficulty,
  [mt.STATUS_SET]: (state, status) => {
    state.status.id = status.id;
    state.status.identifier = status.identifier;
    state.status.description = status.description;
  },
  [mt.SECTION_UPDATE]: (state, sectionData) => {
    const { identifier, ...restData } = sectionData;
    const section = state.sections[identifier];
    if ( !section ) return;

    Object.entries(restData).forEach(([property, value]) => {
        if ( property in section ) section[property] = value ?? section[property];
      }
    );
  },
  [mt.IS_SOME_POPUP_OPENED_SET]: (state, opened) => {
    state.isSomePopupOpened = opened;
  },

  /**
   * @param {Object.<string,any>} state
   * @param {("portrait"|"landscape")} orientation
   */
  [mt.PRINT_AREA_ORIENTATION_SET]: (state, orientation) => {
    state.printArea.orientation = orientation;
  },
  /**
   * @param {Object.<string, any>} state
   * @param {string} paperSize
   */
  [mt.PRINT_AREA_PAPER_SIZE_SET]: (state, paperSize) => {
    state.printArea.paperSize = paperSize;
  },
  /**
   * @param {Object.<string, any>} state
   * @param {boolean} disabled
   */
  [mt.PRINT_AREA_DISABLED_SET]: (state, disabled) => {
    state.printArea.disabled = disabled;
  },
  /**
   * @param {Object.<string, any>} state
   * @param {boolean} disabled
   */
  [mt.PRINT_AREA_VISIBLE_SET]: (state, visible) => {
    state.printArea.visible = visible;
  },
  /**
   * @param {Object.<string, any>} state
   * @param {boolean} [visible]
   */
  [mt.HEADER_VISIBILITY_TOGGLE]: (state, visible = !state.header.visible) => {
    state.header.visible = visible;
  },
  /**
   * @param {Object.<string, any>} state
   * @param {number} value
   */
  [mt.CANVAS_SCALE_VALUE_SET]: (state, value) => {
    state.canvas.scale.value = value;
  },
  /**
   * @param {Object.<string, any>} state
   * @param {number} step
   */
  [mt.CANVAS_SCALE_STEP_SET]: (state, step) => {
    state.scale.step = step;
  }
};
