const path = require('path');

export const loadSvgs = () => {
	const svgs = Object.create(null);
	const context = require.context('./../svg', false, /\.svg$/i);

	context.keys()
		.forEach(key => {
			const fileName = path.basename(key, '.svg');
			const parsedFileName = fileName.replace(/[-_\s]+/ig, '');
			svgs[`vue-icon-component-${parsedFileName}`] = () => import(`./../svg/${path.basename(key)}`);
		});

	return svgs;
};
