const localStorage: Storage = window.localStorage;
const sessionStorage: Storage = window.sessionStorage;

export declare type storageType = 'local' | 'session'

export class WindowStorage {
	/**
	 * @param {string} key
	 * @param {storageType} type
	 */
	static get(key: string, type: storageType = 'session'): string | null {
		return (type === 'session' ? sessionStorage : localStorage).getItem(key);
	}

	/**
	 * @param {string} key
	 * @param {string} val
	 * @param {storageType} type
	 */
	static set(key: string, val: string, type: storageType = 'session'): void {
		(type === 'session' ? sessionStorage : localStorage).setItem(key, val);
	}

	/**
	 * @param {string} key
	 * @param {storageType} type
	 */
	static remove(key: string, type: storageType = 'session'): string | null {
		const val = WindowStorage.get(key, type);
		(type === 'session' ? sessionStorage : localStorage).removeItem(key);
		return val;
	}

	/**
	 * @param {string} key
	 * @param {storageType} [type]
	 */
	static has(key: string, type: storageType = 'session'): boolean {
		return !!WindowStorage.get(key, type);
	}

	/**
	 * @param {storageType} type
	 */
	static clear(type: storageType): void {
		(type === 'session' ? sessionStorage : localStorage).clear();
	}
}
