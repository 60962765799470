import { URLS }          from './config';
import axios             from '__js__/axios';
import reverse           from 'django-reverse';
import { AxiosResponse } from 'axios';
import { vm }            from './../../../';

export function favoriteCardPOST<T = any>(data: any): Promise<AxiosResponse<T>> {
  return axios.post<T>(
    reverse(URLS.MAP_FAVORITE_CARD, vm.$store.state.language),
    data
  );
}
