export enum URLS {
  MAP_UPDATE = 'map_api:map_translate',
  MAP_UPDATE_NAME = 'map_api:map_translate_name',
  LANGUAGE_OPTIONS = 'translate_api:language_options',
  EDITOR_DETAIL = 'map_detail',
  EDITOR_EDIT = 'map_editor',
  EDITOR_CHECK_LAYOUT = 'membership_api:action_check:editor_layout',
  CHECK_MAP_INTERCONNECT = 'membership_api:action_check:map_interconnect',
  CARD_SOFT_DELETE = 'map_api:card_soft_delete',
  TILE = 'map_api:settings:tile',
  PRINT = 'map_api:map_translate_print',
  CAN_EXPORT_PDF = 'map_api:map_translate_can_export_pdf',
  CLONE = 'map_api:map_translate_clone',
  LOCK = 'map_api:map_translate_lock',
  UNLOCK_FORCE = 'map_api:map_translate_force_lock',
  UNLOCK = 'map_api:map_translate_unlock',
  DELETE_MAP_TRANSLATE = 'map_api:settings:delete_map_translate',
  REPORT_INAPPROPRIATE_CONTENT = 'map_api:map_translate_report_inappropriate_content',
  QUICK_EXERCISE = 'exercise_api:exercise_quick_create',
}

export default URLS;
