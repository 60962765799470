import { URLS }          from './config';
import axios             from '__js__/axios';
import reverse           from 'django-reverse';
import { AxiosResponse } from 'axios';
import { vm }            from './../../../';

export function favoriteCardsGET<T = any>({ mapId }: { mapId: string }): Promise<AxiosResponse<T>> {
  return axios.get<T>(
    reverse(URLS.MAP_FAVORITE_CARDS, vm.$store.state.language, mapId)
  );
}
