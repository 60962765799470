export { execAsync }                  from './execAsync';
export { Cookies }                    from './Cookies';
export { WindowStorage }              from './WindowStorage';
export { ImageUtils }                 from './ImageUtils';
export { getRandomNumber }            from './getRandomNumber';
export { getDPI }                     from './getDPI';
export { isDeviceTypeLenovoTB_X306X } from './isDeviceTypeLenovoTB_X306X';
export { checkIsSupportedBrowser }    from './checkIsSupportedBrowser';
export { setText2Clipboard }          from './setText2Clipboard';
export { URLSearchParamsController }  from './URLSearchParamsController';

export const EMAIL_REGEX: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (email: string): boolean => {
  return EMAIL_REGEX.test(email);
};
