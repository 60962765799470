const MAX_SHRINK_IMAGE_WIDTH = 1280;
const MAX_SHRINK_IMAGE_HEIGHT = 1280;

const IMAGE_TYPES = {
  png: "image/png",
  jpg: "image/jpg",
  jpeg: "image/jpeg"
};

export interface ShrinkImgFileOptions {
  maxWidth?: number;
  maxHeight?: number;
  aspectRatioFn?: (options: IAspectRatioOptions) => { width: number; height: number; };
}

export interface IAspectRatioOptions {
  originalHeight: number;
  originalWidth: number;
  width: number;
  height: number;
}

export class ImageUtils {
  static async compress(
    image: HTMLImageElement,
    name?: string,
    type?: string,
    quality?: number,
    width: number = image.width,
    height: number = image.height
  ): Promise<HTMLImageElement> {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(image, 0, 0, width, height);

    return ImageUtils.loadImage(canvas.toDataURL(type, quality ?? 1));
  };

  static async imageToFile(image: HTMLImageElement, fileName?: string, type?: string): Promise<File> {
    const blob: Blob = await ImageUtils.imageToBlob(image, undefined, 1);

    if ( !fileName ) {
      const index = image.src.lastIndexOf("/");
      fileName = index !== -1 ? image.src.substring(index + 1) : image.src;
    }

    if ( !type ) type = blob.type;
    return new File([blob], fileName, { lastModified: Date.now(), type });
  }

  static fileToImage(file: File): Promise<HTMLImageElement> {
    return new Promise(async resolve => {
      const img: HTMLImageElement = new Image();
      const onload = () => {
        img.removeEventListener("load", onload);
        resolve(img);
      };

      img.addEventListener("load", onload);
      img.src = await ImageUtils.fileToBase64(file);
    });
  }

  static fileToBase64(file: File, width?: number, height?: number): Promise<string> {
    return new Promise<string>(resolve => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.toString());
      reader.readAsDataURL(file);
    });
  }

  static imageToBase64(img: HTMLImageElement, type?: string, quality?: number): string {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = img.width;
    canvas.height = img.height;

    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL(type, quality);
  };

  static imageToBlob(img: HTMLImageElement, type?: string, quality?: number): Promise<Blob> {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = img.width;
    canvas.height = img.height;

    ctx.drawImage(img, 0, 0);

    return new Promise<Blob>(resolve =>
      canvas.toBlob(blob => resolve(blob), type, quality)
    );
  }

  static loadImage(src: string): Promise<HTMLImageElement | null> {
    return new Promise<HTMLImageElement | null>((resolve, reject) => {
      const img = new Image();
      const onerror = (_e) => {
        img.removeEventListener("error", onerror);
        img.removeEventListener("load", onload);
        reject(null);
      };

      const onload = () => {
        img.removeEventListener("error", onerror);
        img.removeEventListener("load", onload);
        resolve(img);
      };

      img.addEventListener("load", onload);
      img.addEventListener("error", onerror);
      // img.crossOrigin = 'Anonymous';
      img.src = src;
    });
  }

  static computeAspectRatioDimension(
    { originalWidth, originalHeight, width, height }: IAspectRatioOptions
  ): { width: number, height: number } {
    return originalWidth > originalHeight ?
      {
        width: width,
        height: Math.ceil((originalHeight / originalWidth) * width)
      }
      :
      {
        width: Math.ceil((originalWidth / originalHeight) * height),
        height: height
      };
  }

  static computeAspectRatioDimension2(options: IAspectRatioOptions): { width: number, height: number } {
    const computeWidth = (originalWidth: number, originalHeight: number, height: number): number => {
      return (originalWidth / originalHeight) * height;
    };

    const computeHeight = (originWidth: number, originHeight: number, width: number): number => {
      return (width * originHeight) / originWidth;
    };

    const { originalWidth, originalHeight, height, width } = options;
    const result = {
      width: originalWidth,
      height: originalHeight
    };

    if ( typeof height === "number" && typeof width === "number" ) {
      if ( height > width ) {
        result.width = computeWidth(originalWidth, originalHeight, height);
        result.height = height;
      } else {
        result.width = width;
        result.height = computeHeight(originalWidth, originalHeight, width);
      }
    } else if ( typeof height === "number" && typeof width === "undefined" ) {
      result.width = computeWidth(originalWidth, originalHeight, height);
      result.height = height;
    } else if ( typeof width === "number" && typeof height === "undefined" ) {
      result.width = width;
      result.height = computeHeight(originalWidth, originalHeight, width);
    }

    return {
      width: Math.ceil(result.width),
      height: Math.ceil(result.height)
    };
  }

  static resizeImage(img: HTMLImageElement, width: number, height: number): Promise<HTMLImageElement> {
    return ImageUtils.compress(img, undefined, undefined, 1, width, height);
  }

  static async shrinkImageFile(
    file: File,
    options?: ShrinkImgFileOptions
  ): Promise<File> {
    const {
      maxWidth = MAX_SHRINK_IMAGE_WIDTH,
      maxHeight = MAX_SHRINK_IMAGE_HEIGHT,
      aspectRatioFn = ImageUtils.computeAspectRatioDimension
    } = options ?? {};

    let img = await ImageUtils.fileToImage(file);

    if ( img.naturalWidth > maxWidth || img.naturalHeight > maxHeight ) {
      const { width, height } = aspectRatioFn({
        width: maxWidth,
        height: maxHeight,
        originalWidth: img.naturalWidth,
        originalHeight: img.naturalHeight
      });

      img = await ImageUtils.compress(img, file.name, file.type, 1, width, height);
      return ImageUtils.imageToFile(img, file.name, file.type);
    }

    return file;
  };

  static getTypeByFileExtension(fileName: string): string | null {
    return IMAGE_TYPES[fileName.substring(fileName.lastIndexOf(".") + 1)];
  }
}
