import state from './_state';
import getters from './_getters';
import mutations from './_mutations';
import actions from './_actions';
import * as MUTATION_TYPES from './_mutation-types';

export const VUEX_GLOBAL = {
	NAMESPACE: '',
	MUTATION_TYPES,
	MODULE_DEFINITION: {
		state,
		getters,
		mutations,
		actions
	}
};
