import axios from '__js__/axios';
import { store } from '__vue__/index';
import reverse from 'django-reverse';
import { AxiosResponse } from 'axios';

interface IData {
	text: string;
	mapLanguageId: string;
}

interface IResponseData {
	audioConfig: { audioEncoding: string };
	audioContent: string;
}

export const fetchMediaBase64 = async ({ text, mapLanguageId }: IData): Promise<AxiosResponse<IResponseData>> =>
	await axios.put(
		reverse('map_api:map_language_text_to_speech', store.getters.language, mapLanguageId),
		{ text }
	);
