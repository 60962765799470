<template>
	<div v-show="visible"
	     class="vue-preloader"
	     :class="preloaderCssClass">
		<span class="content"
		      ref="content"
		>
			<slot>
				<div class="object object__one"></div>
				<div class="object object__two"></div>
				<div class="object object__three"></div>
			</slot>
		</span>
	</div>
</template>

<script lang="js">
import { APPLICATION_ROOT_ELEMENT_ID } from '__application-config__';

const SIZES = {
	'es': 'vue-preloader--size-es',
	's': 'vue-preloader--size-s',
	'm': 'vue-preloader--size-m',
	'l': 'vue-preloader--size-l',
	'xl': 'vue-preloader--size-xl'
};

export default {
	name: 'vue-preloader',

	props: {
		zIndex: {
			type: Number,
			required: false
		},
		size: {
			type: String,
			required: false,
			default: 'm',
			validator: v => Object.keys(SIZES).includes(v)
		},
		visible: {
			type: Boolean,
			required: false,
			default: false
		},
		fullScreen: {
			type: Boolean,
			required: false,
			default: false
		},
		backdrop: {
			type: Boolean,
			required: false,
			default: true
		}
	},

	watch: {
		fullScreen(nVal, oVal) {
			if ( nVal === oVal ) return;
			let elem = null;

			if ( nVal && (elem = window.document.getElementById(APPLICATION_ROOT_ELEMENT_ID)) ) {
				elem.append(this.$el);
			} else {
				this.$el && this.$el.parentElement && this.$el.parentElement.append(this.$el);
			}
		}
	},

	computed: {
		preloaderCssClass() {
			return [
				{
					['backdrop']: this.backdrop || this.fullScreen,
					['full-screen']: this.fullScreen
				},
				SIZES[this.size]
			];
		}
	},
	mounted() {
		if ( typeof this.zIndex !== 'undefined' ) {
			this.$el.style.zIndex = this.zIndex;
			this.$refs.content.style.zIndex = this.zIndex + 1;
		}

		if ( this.fullScreen ) {
			window.document.getElementById(APPLICATION_ROOT_ELEMENT_ID).append(this.$el);
		}
	},
	beforeDestroy() {
		if ( this.fullScreen ) {
			this.$el.parentElement.removeChild(this.$el);
		}
	}
};
</script>
