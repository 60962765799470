export default {
	tiles: [],
	isFetching: false,
	nextUrl: null,
	cancelTokenSource: null,
	order: {
		value: null
	},
	filters: {
		tag: {
			value: null
		},
		type: {
			value: null
		},
		person: {
			value: null
		},
		searchQuery: {
			value: null
		},
		language: {
			value: [],
			isFetching: false
		},
		difficulty: {
			value: [],
			isFetching: false
		},
		status: {
			value: [],
			isFetching: false
		}
	}
};
