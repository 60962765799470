import URLS              from './URLS';
import axios             from '__js__/axios';
import { AxiosResponse } from 'axios';
import reverse           from 'django-reverse';
import { vm }            from './../../../../';

interface IData {
	mapTranslateId: string;
	base64: string;
}

export default async ({ mapTranslateId, base64 }: IData): Promise<AxiosResponse<Blob>> => {
	return await axios.post(
		reverse(URLS.PRINT, vm.$store.state.language, mapTranslateId),
		{ data: base64 },
		{
			responseType: 'blob',
			maxContentLength: Number.MAX_SAFE_INTEGER
		}
	);
}
