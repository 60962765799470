import axios  from 'axios';
import { vm } from '@/frontend/vue';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

// function blobResponseErrorInterceptor(error) {
// 	const { response } = error;
//
// 	return new Promise((resolve, reject) => {
// 		const reader = new FileReader();
//
// 		reader.onload = () => {
// 			response.data = JSON.parse(reader.result);
// 			resolve(Promise.reject(error));
// 		};
//
// 		reader.onerror = () => reject(error);
//
// 		reader.readAsText(response.data);
// 	});
// }

function blob2Json(blob) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			resolve(JSON.parse(reader.result.toString()));
		};

		reader.readAsText(blob);
		reader.onerror = (error) => reject(error);
	});
}

axios.interceptors.request.use(
	config => {
		config.headers['Content-Language'] = vm.$store.state.language;
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	response => response,
	async error => {
		const { request, response } = error;

		if (
			request &&
			response &&
			request.responseType === 'blob' &&
			response.data instanceof Blob &&
			(response.data.type?.toLocaleLowerCase() || '').includes('json')
		) {
			response.data = await blob2Json(response.data);
		}

		return Promise.reject(error);
	}
);

export default axios;
