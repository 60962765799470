import VueIcon             from "./Icon/App";
import VueAnimationWrapper from "./wrappers/Animation/App";

const defaultAsyncOptions = { component: null, delay: 200 };

export default {
  VueMainHeader: () => ({ ...defaultAsyncOptions, component: import("./MainHeader/App") }),
  VueProfileEdit: () => ({ ...defaultAsyncOptions, component: import("./profile/ProfileEdit/App") }),
  VueProfile: () => ({ ...defaultAsyncOptions, component: import("./profile/Profile/App") }),
  VueEditorViewWrapper: () =>
    ({ ...defaultAsyncOptions, component: import("./editor/Wrapper/App.vue") }),
  VueEditorView: () =>
    ({ ...defaultAsyncOptions, component: import("./editor/EditorView/App") }),
  VuePopup: () => ({ ...defaultAsyncOptions, component: import("./popup/Base/App") }),
  VueRoadmapDashboard: () => ({ ...defaultAsyncOptions, component: import("./Roadmap/RoadmapList/RoadmapDashboard") }),
  VuePrompt: () => ({ ...defaultAsyncOptions, component: import("./popup/Prompt/App") }),
  VuePromptInput: () => ({ ...defaultAsyncOptions, component: import("./popup/PromptInput/App.vue") }),
  VueLanguageSelector: () => ({ ...defaultAsyncOptions, component: import("./LanguageSelector/App") }),
  VueButton: () => ({ ...defaultAsyncOptions, component: import("./Button/App") }),
  VueTranslateList: () => ({ ...defaultAsyncOptions, component: import("./translate/TranslateList/App") }),
  VueTranslateDetail: () => ({ ...defaultAsyncOptions, component: import("./translate/TranslateDetail/App") }),
  VueNotificationList: () => ({ ...defaultAsyncOptions, component: import("./Notifications/List/App") }),
  VueNotificationBox: () => ({ ...defaultAsyncOptions, component: import("./Notifications/Box/App") }),
  VuePricing: () => ({ ...defaultAsyncOptions, component: import("./store/Pricing/App") }),
  VueOrderSummary: () => ({ ...defaultAsyncOptions, component: import("./store/OrderSummary/App") }),
  VueUserSection: () => ({ ...defaultAsyncOptions, component: import("./profile/UserSection") }),
  VueLogin: () => ({ ...defaultAsyncOptions, component: import("./auth/Login/App") }),
  VueLoginOpener: () => ({ ...defaultAsyncOptions, component: import("./auth/Login/LoginOpener") }),
  VueProgress: () => ({ ...defaultAsyncOptions, component: import("./Progress/App") }),
  VueSignUp: () => ({ ...defaultAsyncOptions, component: import("./auth/SignUp/App") }),

  /* MAP TILE */
  VueMapTile: () => ({
    ...defaultAsyncOptions,
    component: import("./MapTile/Detail/App")
  }),
  VueMapTileInputSearch: () => ({
    ...defaultAsyncOptions,
    component: import("./MapTile/inputs/Search/App")
  }),
  VueMapTileInputDifficulty: () => ({
    ...defaultAsyncOptions,
    component: import("./MapTile/inputs/Difficulty/App.vue")
  }),
  VueMapTileInputOrder: () => ({
    ...defaultAsyncOptions,
    component: import("./MapTile/inputs/Order/App.vue")
  }),
  VueMapTileInputLanguage: () => ({
    ...defaultAsyncOptions,
    component: import("./MapTile/inputs/Language/App.vue")
  }),
  VueMapTileInputStatus: () => ({
    ...defaultAsyncOptions,
    component: import("./MapTile/inputs/Status/App.vue")
  }),
  VueMapTileFilterControls: () => ({
    ...defaultAsyncOptions,
    component: import("./MapTile/FilterControls/App.vue")
  }),
  VueMapTileFilterConstraints: () => ({
    ...defaultAsyncOptions,
    component: import("./MapTile/FilterConstraints/App.vue")
  }),
  VueMapTileMapsPreview: () => ({
    ...defaultAsyncOptions,
    component: import("./MapTile/MapsPreview/App.vue")
  }),

  VueTag: () => ({ ...defaultAsyncOptions, component: import("./Tag/App") }),
  VueTopicsHomePage: () => ({ ...defaultAsyncOptions, component: import("./Topics/TopicsHomePage") }),
  VueExerciseHomePage: () => ({ ...defaultAsyncOptions, component: import("./Exercise/ExerciseHomePage") }),
  VueExerciseDetail: () => ({
    ...defaultAsyncOptions,
    component: import("./Exercise/components/Exercise/ExerciseDetail")
  }),
  VueExerciseAttemptDetail: () => ({
    ...defaultAsyncOptions,
    component: import("./Exercise/components/ExerciseAttempt/ExerciseAttemptDetail")
  }),
  VuePasswordResetForm: () => ({ ...defaultAsyncOptions, component: import("./profile/PasswordResetForm") }),
  VuePasswordChangeForm: () => ({ ...defaultAsyncOptions, component: import("./profile/PasswordChangeForm") }),
  VueIcon,
  VueAnimationWrapper
};
