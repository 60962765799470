import state from './_state';
import getters from './_getters';
import mutations from './_mutations';
import actions from './_actions';
import * as MUTATION_TYPES from './_mutation-types.js';
import * as ACTION_TYPES from './_action-types.js';

export const VUEX_EDITOR = {
	NAMESPACE: 'VUEX_EDITOR',
	MUTATION_TYPES,
	ACTION_TYPES,
	MODULE_DEFINITION: {
		namespaced: true,
		state,
		getters,
		mutations,
		actions

	}
};
