import api from '__components__/editor/EditorView/api';
import { execAsync } from '__application-utils__';
import * as mt from './_mutation-types';
import * as at from './_action-types';

export default {
	fetchTile: async ({ commit }, { mapTranslateId }) => {
		const { data: response, error } = await execAsync(api.fetchTile({ mapTranslateId }));

		if (response) {
		  commit(mt.TILE_SET, response.data);
    }

		if (error) {
		  throw error;
    }

		return response;
	},

	/**
	 * @param commit
	 * @param { Object } payload
	 * @param { string } payload.identifier
	 * @param { boolean } payload.isOpen
	 * @param { boolean } payload.isActive
	 */
	[at.sectionUpdate]({ commit }, payload) {
		commit(mt.SECTION_UPDATE, payload);
	}

};
