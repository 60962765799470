/* SORT BY */
export const ORDER_SET = 'ORDER::SET';

/* FILTER PERSON */
export const FILTER_PERSON_SET = 'FILTER_PERSON::SET';

/* TAG */
export const FILTER_TAG_SET = 'FILTER_TAG::SET';

/* FILTER TYPE */
export const FILTER_TYPE_SET = 'FILTER_TYPE::SET';

/* FILTER SEARCH TERM */
export const FILTER_SEARCH_QUERY_SET = 'FILTER_SEARCH_QUERY::SET';

/* FILTER LANGUAGE */
export const FILTER_LANGUAGE_SET = 'FILTER_LANGUAGE::SET';
export const FILTER_LANGUAGE_REMOVE = 'FILTER_LANGUAGE::REMOVE';
export const FILTER_LANGUAGE_ADD = 'FILTER_LANGUAGE::ADD';
export const FILTER_LANGUAGE_CLEAR = 'FILTER_LANGUAGE::CLEAR';
export const FILTER_LANGUAGE_IS_FETCHING_SET = 'FILTER_LANGUAGE_IS_FETCHING::SET';

/* FILTER DIFFICULTY */
export const FILTER_DIFFICULTY_SET = 'FILTER_DIFFICULTY::SET';
export const FILTER_DIFFICULTY_REMOVE = 'FILTER_DIFFICULTY::REMOVE';
export const FILTER_DIFFICULTY_ADD = 'FILTER_DIFFICULTY::ADD';
export const FILTER_DIFFICULTY_CLEAR = 'FILTER_DIFFICULTY::CLEAR';
export const FILTER_DIFFICULTY_IS_FETCHING_SET = 'FILTER_DIFFICULTY_IS_FETCHING::SET';

/* FILTER STATUS */
export const FILTER_STATUS_SET = 'FILTER_STATUS::SET';
export const FILTER_STATUS_REMOVE = 'FILTER_STATUS::REMOVE';
export const FILTER_STATUS_ADD = 'FILTER_STATUS::ADD';
export const FILTER_STATUS_CLEAR = 'FILTER_STATUS::CLEAR';
export const FILTER_STATUS_IS_FETCHING_SET = 'FILTER_STATUS_IS_FETCHING::SET';

/* TILES */
export const TILES_SET = 'TILES::SET';
export const TILES_ADD = 'TILES::ADD';

/* METADATA */
export const NEXT_URL_SET = 'NEXT_URL::SET';
export const CANCEL_TOKEN_SOURCE_SET = 'CANCEL_TOKEN_SOURCE::SET';
export const IS_FETCHING_SET = 'IS_FETCHING::SET';
