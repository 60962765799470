export const create = () => {
	return {
		beforeCreate() {
			// Binding the this to the $modal handlers
			if ( this.$modal ) {
				Object.entries(this.$modal)
							.forEach(([key, val]) => {
								if ( typeof val === 'function' ) this.$modal[key] = val.bind(this);
							});
			}
		},
		created() {
			if ( !this.user.authenticated ) return;
			this.fetchFetchMembershipUser();
		},
		mounted() {
			this.addEventListeners();
		},
		beforeDestroy() {
			this.removeEventListeners();
		}
	};
};
