import axios                       from 'axios';
import * as MT                     from './_mutation-types';
import * as AT                     from './_action-types';
import { execAsync }               from '__application-utils__';
import { fetchData as fetchTiles } from '__components__/MapTile/MapsPreview/api';

export default {
	/**
	 * @param {import("vuex").ActionContext} ctx
	 * @param {Object} [options]
	 * @param {boolean} [options.append]
	 */
	async [AT.fetchTiles](ctx, options) {
		const { append: _append } = { append: false, ...options };
		const cancelTokenSource = axios.CancelToken.source();

		if ( !_append ) {
			ctx.commit(MT.NEXT_URL_SET, null);
			ctx.commit(MT.TILES_SET, []);
		}

		ctx.commit(MT.IS_FETCHING_SET, true);


		if ( ctx.state.cancelTokenSource ) {
			await ctx.dispatch(AT.cancelRequest);
		}

		await ctx.commit(
			`${ MT.CANCEL_TOKEN_SOURCE_SET }`,
			cancelTokenSource
		);

		const { data: response, error } = await execAsync(
			fetchTiles({
				...createPayload(ctx),
				cancelToken: cancelTokenSource.token,
				url: _append ? (ctx.getters.nextUrl ?? undefined) : undefined
			})
		);

		if ( cancelTokenSource === ctx.state.cancelTokenSource ) {
			ctx.commit(MT.CANCEL_TOKEN_SOURCE_SET, null);
			ctx.commit(MT.IS_FETCHING_SET, false);
		}

		if ( error ) {
			throw error;
		}

		if ( response ) {
			const { data } = response;

			ctx.commit(
				_append ? `${ MT.TILES_ADD }` : `${ MT.TILES_SET }`,
				data.results
			);

			ctx.commit(
				MT.NEXT_URL_SET,
				data.next
			);
		}

		return response;
	},

	/**
	 * @param {import("vuex").ActionContext} ctx
	 * @returns {Promise<import("__components__/MapTile/MapsPreview/api").IData>}
	 */
	async [AT.createFilterParams](ctx) {
		return createPayload(ctx);
	},

	/**
	 * @param {import("vuex").ActionContext} ctx
	 */
	async [AT.cancelRequest](ctx) {
		ctx.state.cancelTokenSource?.cancel();

		ctx.commit(
			`${ MT.CANCEL_TOKEN_SOURCE_SET }`,
			null
		);
	},

	/**
	 * @param {import("vuex").ActionContext} ctx
	 * @returns {Promise<void>}
	 */
	async [AT.processSearch](ctx) {
		await ctx.dispatch(AT.fetchTiles);
	}
};

/**
 * @param {import("vuex").ActionContext} ctx
 * @return {import("__components__/MapTile/MapsPreview/api").IData}
 */
function createPayload(ctx) {
	const { order, filters } = ctx.state;
	const ordering = order.value;
	const search = filters.searchQuery.value;
	const personId = filters.person.value;
	const type = filters.type.value;
	const tag = filters.tag.value;

	const language = filters.language.value.map(item => item.id);
	const status = filters.status.value.map(item => item.id);
	const difficulty = filters.difficulty.value.map(item => item.id);

	return {
		personId: normalizePayloadValue(personId),
		search: normalizePayloadValue(search),
		ordering: normalizePayloadValue(ordering),
		tag: normalizePayloadValue(tag),
		type: normalizePayloadValue(type),
		mapLanguages: normalizePayloadValue(language),
		statuses: normalizePayloadValue(status),
		difficulties: normalizePayloadValue(difficulty)
	};
}

/**
 * @param {any} value
 * @returns {any}
 */
function normalizePayloadValue(value) {
	if ( typeof value === 'string' && !value.length ) {
		return undefined;
	}

	if ( Array.isArray(value) && !value.length ) {
		return undefined;
	}

	return value ?? undefined;
}
