export interface Options {
  url: string;
}

function _createUrl(options?: Partial<Options>): URL {
  const { url } = Object.assign<Options, Partial<Options>>(
    {
      url: window.location.href
    },
    options ?? {}
  );

  return new URL(url);
}

export class URLSearchParamsController {
  static has(name: string, options?: Options): boolean {
    return _createUrl(options).searchParams.has(name);
  }

  static get(name: string, options?: Options): string {
    return _createUrl(options).searchParams.get(name);
  }

  static set(params: { [name: string]: string }, options?: Options): void {
    const url = _createUrl(options);

    Object.entries(params).forEach(([name, value]) => {
      url.searchParams.set(name, value);
    });

    window.history.replaceState(undefined, undefined, url.toString());
  }

  static delete(names: string[], options?: Options): void {
    const url = _createUrl(options);

    names.forEach(name => {
      url.searchParams.delete(name);
    });

    window.history.replaceState(undefined, undefined, url.toString());
  }
}
