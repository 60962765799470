// POPUP
export const POPUP_SHOW = 'POPUP::SHOW';
export const POPUP_HIDE = 'POPUP::HIDE';
export const POPUP_DESTROY = 'POPUP::DESTROY';

// PRELOADER
export const PRELOADER_SHOW = 'PRELOADER::SHOW';
export const PRELOADER_HIDE = 'PRELOADER::HIDE';
export const PRELOADER_TOGGLE = 'PRELOADER::TOGGLE';

// EDITOR
export const EDITOR_BACKGROUND_SET = 'EDITOR::BACKGROUND::SET';
export const EDITOR_STATE_GET = 'EDITOR::STATE::GET';
export const EDITOR_CARD_DELETE = 'EDITOR::CARD::DELETE';
export const EDITOR_CARD_PROPS_UPDATE = 'EDITOR::CARD::PROPS::UPDATE';
export const EDITOR_CARD_TEXTURING_TOGGLE = 'EDITOR::CARD::TEXTURING::TOGGLE';
export const EDITOR_CARD_TEXTURING_SECONDARY_TOGGLE = 'EDITOR::CARD::TEXTURING::SECONDARY::TOGGLE';
export const EDITOR_CARD_TEXTURING_SECONDARY_ACTIVATE = 'EDITOR::CARD::TEXTURING::SECONDARY::ACTIVATE';
export const EDITOR_CARD_TEXTURING_SECONDARY_DEACTIVATE = 'EDITOR::CARD::TEXTURING::SECONDARY::DEACTIVATE';

export const EDITOR_CHOOSING_FAVORITE_CARDS_ACTIVATE = 'EDITOR::CHOOSING_FAVORITE_CARDS::ACTIVATE';
export const EDITOR_CHOOSING_FAVORITE_CARDS_DEACTIVATE = 'EDITOR::CHOOSING_FAVORITE_CARDS::DEACTIVATE';

export const EDITOR_CARDS_SHOW = 'EDITOR::FAVORITE_CARDS::SHOW';
export const EDITOR_FAVORITE_CARDS_HIDE = 'EDITOR::FAVORITE_CARDS::HIDE';
export const EDITOR_UNFAVORITE_CARDS_HIDE = 'EDITOR::UNFAVORITE_CARDS::HIDE';

export const EDITOR_TEXTURING_ACTIVATE = 'EDITOR::TEXTURING::ACTIVATE';
export const EDITOR_TEXTURING_DEACTIVATE = 'EDITOR::TEXTURING::DEACTIVATE';
export const EDITOR_UNLOCK_AND_REDIRECT = 'EDITOR::UNLOCK_AND_REDIRECT';
export const EDITOR_WORDS_STACK_ACTIVATE = 'EDITOR::WORDS_STACK::ACTIVATE';
export const EDITOR_WORDS_STACK_DEACTIVATE = 'EDITOR::WORDS_STACK::DEACTIVATE';

export const EDITOR_IMAGE_GALLERY_TAB_DATABASE_RELOAD = 'EDITOR::IMAGE_GALLERY::TAB_DATABASE::RELOAD';

export const EDITOR_IMAGE_GALLERY_TAB_OWN_RELOAD = 'EDITOR::IMAGE_GALLERY::TAB_OWN::RELOAD';

export const EDITOR_IMAGE_GALLERY_TAB_UNUSED_RELOAD = 'EDITOR::IMAGE_GALLERY::TAB_UNUSED::RELOAD';
export const EDITOR_IMAGE_GALLERY_TAB_UNUSED_ITEM_DELETE = 'EDITOR::IMAGE_GALLERY::TAB_UNUSED::ITEM_DELETE';
export const EDITOR_IMAGE_GALLERY_TAB_UNUSED_ITEM_ADD = 'EDITOR::IMAGE_GALLERY::TAB_UNUSED::ITEM_ADD';


// NOTIFICATION BOX
export const NOTIFICATION_BOX_IS_VISIBLE = 'NOTIFICATION_BOX::IS_VISIBLE';
export const NOTIFICATION_BOX_SHOW = 'NOTIFICATION_BOX::SHOW';
export const NOTIFICATION_BOX_HIDE = 'NOTIFICATION_BOX::HIDE';
export const NOTIFICATION_BOX_TOGGLE = 'NOTIFICATION_BOX::TOGGLE';

// MAP TILE LIST
export const MAP_TILE_LIST_SEARCH = 'MAP_TILE_LIST::SEARCH';
export const MAP_TILE_LIST_ORDER_GET_VALUE = 'MAP_TILE_ORDER::GET_VALUE';
export const MAP_TILE_LIST_SEARCH_GET_VALUE = 'MAP_TILE_SEARCH::GET_VALUE';

// EXERCISES
export const EXERCISE_LIST_REFRESH = 'EXERCISE::LIST_REFRESH';
export const EXERCISE_SAVE_CONFIG = 'EXERCISE::SAVE_CONFIG';
export const EXERCISE_FETCH_EXERCISE = 'EXERCISE::FETCH_EXERCISE';
export const EXERCISE_CONFIG_ERROR_TRUE = 'EXERCISE::CONFIG_ERROR_TRUE';
export const EXERCISE_CONFIG_ERROR_FALSE = 'EXERCISE::CONFIG_ERROR_FALSE';
export const EXERCISE_CONFIG_UNSAVED = 'EXERCISE::EXERCISE_CONFIG_UNSAVED';
export const EXERCISE_CONFIG_SAVED = 'EXERCISE::EXERCISE_CONFIG_SAVED';
export const EXERCISE_INITIALIZED = 'EXERCISE::INITIALIZED';

// EDITOR
export const EDITOR_OPEN_SETTINGS = 'EDITOR::OPEN_SETTINGS';

export const EDITOR_OPEN_PUBLISH = 'EDITOR::OPEN_PUBLISH';
export const EDITOR_ON_PUBLISH_CLOSE = 'EDITOR::ON_PUBLISH_CLOSE';
export const EDITOR_ON_PUBLISH_SAVE = 'EDITOR::ON_PUBLISH_SAVE';
export const EDITOR_SECTION_CLOSED = 'EDITOR::SECTION_CLOSED';
export const EDITOR_SECTION_OPENED = 'EDITOR::SECTION_OPENED';
