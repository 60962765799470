import EVENT_BUS, { EVENT_BUS_TYPES } from '__event-bus__';
import api                            from '__application-api__';
import { MODAL_TYPES }                from '__application-config__';
import { WindowStorage, execAsync }   from '__application-utils__';
import { ErrorHandler }               from '__mixins__';

export const create = () => {

  return {
    /*********************************************************************************************************************
     * PRELOADER
     ********************************************************************************************************************/

    /**
     * Show the global full-screen preloader.
     */
    preloaderShow({ visible, fullscreen, backdrop } = { visible: true, fullscreen: true, backdrop: true }) {
      this.preloader.visible = visible;
      this.preloader.fullscreen = fullscreen;
      this.preloader.backdrop = backdrop;
    },

    /**
     * Hide the global full-screen preloader.
     */
    preloaderHide() {
      this.preloader.visible = false;
      this.preloader.fullscreen = false;
      this.preloader.backdrop = false;
    },

    /**
     * Toggle the global full-screen preloader.
     */
    preloaderToggle(visible) {
      if ( typeof visible === 'boolean' ) {
        this.preloader.visible = visible;
      } else {
        this.preloader.visible = !this.preloader.visible;
      }
    },

    /**
     * Register the event listeners on the EventBus.
     */
    addEventListeners() {
      EVENT_BUS.$on(EVENT_BUS_TYPES.PRELOADER_SHOW, this.preloaderShow);
      EVENT_BUS.$on(EVENT_BUS_TYPES.PRELOADER_HIDE, this.preloaderHide);
      EVENT_BUS.$on(EVENT_BUS_TYPES.PRELOADER_TOGGLE, this.preloaderToggle);
    },

    /**
     * Remove the event listeners from the EventBus.
     */
    removeEventListeners() {
      EVENT_BUS.$off();
    },

    /********************************************************************************************************************
     * NOTIFICATION
     ********************************************************************************************************************/
    notificationBoxShow(event, options) {
      EVENT_BUS.$emit(EVENT_BUS_TYPES.NOTIFICATION_BOX_SHOW, { event, options });
    },

    notificationBoxToggle(event, options) {
      EVENT_BUS.$emit(EVENT_BUS_TYPES.NOTIFICATION_BOX_TOGGLE, { event, options });
    },

    notificationBoxHide(event, id) {
      EVENT_BUS.$emit(EVENT_BUS_TYPES.NOTIFICATION_BOX_HIDE, { event, id });
    },

    /*********************************************************************************************************************
     * OTHER METHODS
     *********************************************************************************************************************/

    login() {
      this.$modal.show(MODAL_TYPES.LOGIN);
    },

    async fetchFetchMembershipUser() {
      const { data: response, error } = await execAsync(api.fetchFetchMembershipUser());

      if ( error ) {
        ErrorHandler.methods.handleError.call(this, error);
        return;
      }

      this.$store.commit(
        this.$store.VUEX_GLOBAL.MUTATION_TYPES.MEMBERSHIP_SET,
        response.data
      );
    },

    async editorMapDelete({ mapId }) {
      return await api.EDITOR.mapDelete({ mapTranslateId: mapId });
    },

    async editorMapDetail({ mapTranslateId, url, mapId, openNewTab }) {
      let _url = url;

      if ( !_url && mapTranslateId ) {
        _url = await api.EDITOR.mapDetail({ mapTranslateId });
      }

      if ( mapId ) WindowStorage.set(`${ this.$store.state.user.id }-${ mapId }`, window.location.pathname);

      openNewTab ?
        window.open(_url, '_blank') :
        window.location.href = _url;
    },

    async editorMapCreate() {
      const processCreateMap = async () => {
        this.$modal.hideAll();
        this.preloaderShow();

        try {
          const { data } = await api.EDITOR.mapCreate();
          const key = `${ this.$store.state.user.id }-${ data.map.id }`;
          WindowStorage.set(key, window.location.pathname);
          window.location.href = data.redirect_url;
        } catch ( e ) {
          this.$toast.error({ title: `Error fetching map create`, message: `${ e.toString() }` });
        } finally {
          this.preloaderHide();
        }
      };

      if ( !this.$store.state.user.authenticated ) {
        await this.$modal.show(MODAL_TYPES.LOGIN, { propsData: { onSuccessHandlerFn: processCreateMap } });
      } else {
        await processCreateMap();
      }
    }
  };
};
