import { DEVICE_TYPES } from '__application-config__';

export default {
  language: null,
  browser: null,
  isUnsupportedBrowser: false,
  deviceType: DEVICE_TYPES.DESKTOP,
  user: {
    id: null,
    authenticated: false,
    username: null,
    email: null
  },
  membership: null
};
