import Vue             from '__vue__/vue';
import Vuex, { Store } from 'vuex';
import {
  VUEX_EDITOR,
  VUEX_TILES,
  VUEX_GLOBAL
}                      from './modules';

Store.prototype.VUEX_GLOBAL = VUEX_GLOBAL;
Store.prototype.VUEX_EDITOR = VUEX_EDITOR;
Store.prototype.VUEX_TILES = VUEX_TILES;

Vue.use(Vuex);

const initState = (state, data) => {
  state.language = data.language;
  state.browser = data.browser;
  state.isSupportedBrowser = data.isSupportedBrowser;
  state.deviceType = data.deviceType;

  state.user.id = data.user.id;
  state.user.authenticated = data.user.authenticated;
  state.user.username = data.user.username;
  state.user.email = data.user.email;

  return state;
};

export const create = (initialData = {}) => {
  const { state, getters, mutations, actions } = VUEX_GLOBAL.MODULE_DEFINITION;

  return new Store({
    strict: process.env.NODE_ENV !== 'production',
    state: initState(state, initialData),
    getters,
    mutations,
    actions,
    modules: {
      [VUEX_EDITOR.NAMESPACE]: VUEX_EDITOR.MODULE_DEFINITION,
      [VUEX_TILES.NAMESPACE]: VUEX_TILES.MODULE_DEFINITION
    }
  });
};

export {
  VUEX_GLOBAL,
  VUEX_EDITOR,
  VUEX_TILES
};
