import URLS_                          from './URLS';
import mapUpdate                      from './_mapUpdate';
import mapUpdateName                  from './_updateMapName';
import mapDetail                      from './_mapDetail';
import mapCreate                      from './_mapCreate';
import mapDelete                      from './_deleteMap';
import fetchLanguageOptions           from './_fetchLanguageOptions';
import cardSoftDelete                 from './_cardSoftDelete';
import fetchTile                      from './_fetchTile';
import print                          from './_print';
import lock                           from './_lock';
import unlockForce                    from './_unlockForce';
import unlock                         from './_unlock';
import clone                          from './_clone';
import deleteLanguageInstance         from './_deleteMapTranslate';
import { fetchMediaBase64 }           from './_fetchMediaBase64';
import { editorCheckLayout }          from './_editorCheckLayout';
import { checkMapInterconnection }    from './_checkMapInterconnection';
import { canExportPdf }               from './_canExportPdf';
import { reportInappropriateContent } from './_reportInappropriateContent';
import quickExercise                  from './_quickExercise';

export const URLS = URLS_;

export default {
  checkMapInterconnection,
  editorCheckLayout,
  mapUpdate,
  mapUpdateName,
  mapDetail,
  mapCreate,
  mapDelete,
  fetchLanguageOptions,
  cardSoftDelete,
  fetchTile,
  print,
  lock,
  unlock,
  unlockForce,
  clone,
  deleteLanguageInstance,
  fetchMediaBase64,
  canExportPdf,
  reportInappropriateContent,
  quickExercise
};
