export const TILE_SET = "TILE::SET";
export const TILE_DIFFICULTY_SET = "TILE_DIFFICULTY::SET";
export const STATUS_SET = "STATUS::SET";
export const SECTION_UPDATE = "SECTION::UPDATE";
export const IS_SOME_POPUP_OPENED_SET = "IS_SOME_POPUP_OPENED::SET";

export const HEADER_VISIBILITY_TOGGLE = "HEADER_VISIBILITY::TOGGLE";

export const PRINT_AREA_ORIENTATION_SET = "PRINT_AREA_ORIENTATION::SET";
export const PRINT_AREA_PAPER_SIZE_SET = "PRINT_AREA_PAPER_SIZE::SET";
export const PRINT_AREA_DISABLED_SET = "PRINT_AREA_DISABLED::SET";
export const PRINT_AREA_VISIBLE_SET = "PRINT_AREA_VISIBLE::SET";

export const CANVAS_SCALE_VALUE_SET = "CANVAS_SCALE_VALUE::SET";
export const CANVAS_SCALE_STEP_SET = "CANVAS_SCALE_STEP::SET";
