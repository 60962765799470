import { URLS } from './config';
import axios from '__js__/axios';
import reverse from 'django-reverse';
import { AxiosResponse } from 'axios';
import { vm } from './../../../';

interface IData {
	mapTranslateId: string;
}

export default async <T>({ mapTranslateId }: IData): Promise<AxiosResponse<T>> => {
	return await axios.get<T>(reverse(URLS.MAP_DETAIL, vm.$store.state.language, mapTranslateId));
};
