export const create = (initialData) => {
	return {
		preloader: {
			visible: false,
			fullscreen: true,
			backdrop: true
		},
		user: {
			authenticated: initialData.authenticated
		}
	};
};
