import Vue                   from "./../vue";
import * as data             from "./_data";
import * as computed         from "./_computed";
import * as  watch           from "./_watch";
import * as lifecycleMethods from "./_lifecycleMethods";
import * as methods          from "./_methods";
import components            from "./../components";
import {
  APPLICATION_ROOT_ELEMENT_ID,
  BROWSER_TYPES,
  DEVICE_TYPES
}                            from "./config";

// 3rd party libraries
import * as VueI18n from "./../libs/vueI18n";
import "./../libs/vuebar";
import "./../libs/cxlt-vue2-toastr";
import "./../libs/Modal";

import * as Store                  from "./../store";
import { checkIsSupportedBrowser } from "__application-utils__";

/**
 * @param {string} value
 * @returns {boolean}
 */
function isNoneOrEmpty(value) {
  const v = (value ?? "").toLowerCase();
  return !v || v === "none";
}

export const create = ({ el, template }) => {
  const initialData = {
    isSupportedBrowser: checkIsSupportedBrowser(),
    language: !isNoneOrEmpty(template.dataset.language) ?
      template.dataset.language :
      null,
    authenticated: !isNoneOrEmpty(template.dataset.userAuthenticated)
      ? template.dataset.userAuthenticated === "True"
      : false,
    email: !isNoneOrEmpty(template.dataset.userEmail) ?
      template.dataset.userEmail :
      null,
    username: !isNoneOrEmpty(template.dataset.userName) ?
      template.dataset.userName :
      null,
    userId: !isNoneOrEmpty(template.dataset.userId) ?
      template.dataset.userId :
      null
  };

  const user = {
    id: initialData.userId,
    username: initialData.username,
    email: initialData.email,
    authenticated: initialData.authenticated
  };

  const i18n = VueI18n.create({
    language: initialData.language
  });

  const store = Store.create({
    language: initialData.language,
    browser: checkBrowser(),
    deviceType: checkDeviceType(),
    isSupportedBrowser: initialData.isSupportedBrowser,
    user
  });

  document.body.classList.add(
    `browser--${ checkBrowser() }`,
    `device--${ checkDeviceType() }`
  );

  if ( initialData.authenticated ) {
    document.body.classList.add("user-authenticated");
  }

  const rootElClassList = [
    !initialData.isSupportedBrowser ?
      "unsupported-browser" :
      null
  ].filter(Boolean).join(" ");

  const vm = new Vue({
    el: el,
    template: `
      <div
        id="${ APPLICATION_ROOT_ELEMENT_ID }"
        data-page="${ checkPage() }"
        class="${ rootElClassList }"
      >
      ${ template.innerHTML }
      </div>`,
    i18n,
    store,
    data: data.create({
        authenticated: initialData.authenticated,
        isSupportedBrowser: initialData.isSupportedBrowser
      }
    ),
    computed: computed.create(),
    watch: watch.create(),
    ...lifecycleMethods.create(),
    methods: methods.create(),
    components
  });

  vm.$modal.show.bind(vm);

  return { vm, i18n, store };
};

/**
 * @returns {boolean}
 */
function isMobileDevice() {
  return (
    isTouchDevice() &&
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/i.test(window.navigator.userAgent)
  );
}

/**
 * @returns {boolean}
 */
function isTabletDevice() {
  return (
    isTouchDevice() &&
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i.test(window.navigator.userAgent)
  );

}

/**
 * @returns {boolean}
 */
// function isMobileDevice() {
//   // iPhone
//   const mediaQueryList = [
//     /* ----------- iPhone 4 and 4S ----------- */
//     /* Portrait and Landscape */
//     '(min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2)',
//     /* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
//     /* Portrait and Landscape */
//     '(min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2)',
//     /* ----------- iPhone 6, 6S, 7 and 8 ----------- */
//     /* Portrait and Landscape */
//     '(min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2)',
//     /* ----------- iPhone 6+, 7+ and 8+ ----------- */
//     /* Portrait and Landscape */
//     '(min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3)',
//     /* ----------- iPhone X ----------- */
//     /* Portrait and Landscape */
//     '(min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3)',
//     /* ----------- Galaxy S3 ----------- */
//     /* Portrait and Landscape */
//     '(device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2)',
//     /* ----------- Galaxy S4, S5 and Note 3 ----------- */
//     /* Portrait and Landscape */
//     '(device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3)',
//     /* ----------- Galaxy S6 ----------- */
//     /* Portrait and Landscape */
//     '(device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4)',
//     /* ----------- HTC One ----------- */
//     /* Portrait and Landscape */
//     '(device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3)',
//     /* ----------- Google Pixel ----------- */
//     /* Portrait and Landscape */
//     '(device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3)',
//     /* ----------- Google Pixel XL ----------- */
//     /* Portrait and Landscape */
//     '(device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4)',
//     /* ----------- Nexus 4 ----------- */
//     /* Portrait and Landscape */
//     '(device-width: 384px) and (device-width: 592px) and (-webkit-device-pixel-ratio: 2)',
//     /* ----------- Nexus 5 ----------- */
//     /* Portrait and Landscape */
//     '(device-width: 360px) and (max-device-width: 592px) and (-webkit-device-pixel-ratio: 3)',
//     /* ----------- Nexus 6 and 6P ----------- */
//     /* Portrait and Landscape */
//     '(device-width: 360px) and (device-width: 592px) and (-webkit-device-pixel-ratio: 4)',
//     /* ----------- Windows Phone ----------- */
//     /* Portrait and Landscape */
//     '(device-width: 480px) and (device-height: 800px)'
//   ];
//
//   // const mediaQueryList = window.matchMedia(
//   //   '(orientation: portrait) and (max-width: 600px), (orientation: landscape) and (max-width: 900px)'
//   // );
//
//   return mediaQueryList
//     .map(q => window.matchMedia(q))
//     .some(i => i.matches);
// }

function checkPage() {
  const pathParts = window.location.pathname.split("/").filter(Boolean);

  if ( pathParts.length === 1 ) {
    return "home";
  }

  return "";
}

/**
 * @returns {boolean}
 */
function isTouchDevice() {
  return (
    (window.matchMedia(`(pointer: coarse)`).matches) &&
    (
      ("ontouchstart" in window) ||
      ("maxTouchPoints" in navigator && navigator.maxTouchPoints > 0) ||
      ("msMaxTouchPoints" in navigator && navigator.msMaxTouchPoints > 0)
    )
  );
}

/**
 * @returns {("mobile"|"tablet"|"desktop")}
 */
function checkDeviceType() {
  if ( isTabletDevice() ) {
    return DEVICE_TYPES.TABLET;
  }

  if ( isMobileDevice() ) {
    return DEVICE_TYPES.MOBILE;
  }

  return DEVICE_TYPES.DESKTOP;
}

function checkBrowser() {
  const userAgent = window.navigator.userAgent;

  if ( (userAgent.indexOf("Opera") || navigator.userAgent.indexOf("OPR")) !== -1 )
    return BROWSER_TYPES.OPERA;
  if ( userAgent.indexOf("Chrome") !== -1 )
    return BROWSER_TYPES.CHROME;
  if ( userAgent.indexOf("Safari") !== -1 )
    return BROWSER_TYPES.SAFARI;
  if ( userAgent.indexOf("Firefox") !== -1 )
    return BROWSER_TYPES.FIREFOX;
  if ( (userAgent.indexOf("MSIE") !== -1) || (!!window.document.documentMode) ) //IF IE > 10
    return BROWSER_TYPES.IE;

  return BROWSER_TYPES.CHROME;
}
