import { URLS } from './config';
import axios from '__js__/axios';
import reverse from 'django-reverse';
import { AxiosResponse } from 'axios';
import { vm } from './../../../';

interface IData {
	data?: any,
	reverseParams?: any[]
}

export default async <T>(
	{ data = undefined, reverseParams = [] }: IData =
		{
			data: undefined,
			reverseParams: []
		}
): Promise<AxiosResponse<T>> => {

	return await axios.post<T>(reverse(URLS.MAP_CREATE, vm.$store.state.language, ...reverseParams), data);
};
