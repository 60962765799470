import axios             from '__js__/axios';
import reverse           from 'django-reverse';
import URLS              from './URLS';
import { AxiosResponse } from 'axios';
import { vm }            from './../../../../';

export const canExportPdf = ({
                               extended,
                               mapTranslateId
                             }: { extended?: boolean, mapTranslateId: string }):
  Promise<AxiosResponse> => {
  return axios.get(
    reverse(URLS.CAN_EXPORT_PDF, vm.$store.state.language, mapTranslateId),
    {
      params: {
        extended
      }
    }
  );
};
