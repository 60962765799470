import URLS from './URLS';
import axios from '__js__/axios/index';
import reverse from 'django-reverse';
import { AxiosResponse } from 'axios';
import { ILanguage } from './../types/language.interface';

interface IResponseData {
	count: number;
	next: string;
	previous: string;
	results: ILanguage[];
}

export default async (): Promise<AxiosResponse<IResponseData>> => {
	return await axios.get<IResponseData>(reverse(URLS.LANGUAGE_OPTIONS));
}
