<!--
IMPORTANT:
Kdyz zadavas v django template <vue-icon ...>,
tak je parovy tag <vue-icon type="check-circle"></vue-icon>,
ve Vue template uzivej <vue-icon type="check-circle" />
 -->

<template>
	<vue-animation-wrapper
		v-if="useAnimation"
		animation-name="vue-animation-fade"
		:appear="animationAfterMount"
	>
		<component :is="typeWithPrefix"
							 :key="typeWithPrefix"
		/>
	</vue-animation-wrapper>
	<component
		v-else
		:is="typeWithPrefix"
		:key="typeWithPrefix"
	/>
</template>

<script lang="js">
	import { loadSvgs } from './utils/loadSvgs.js';
	import VueAnimationWrapper from '__components__/wrappers/Animation/App';

	const svgsComponents = loadSvgs();

	export default {
		name: 'vue-icon-component',
		props: {
			useAnimation: { type: Boolean, required: false, default: true },
			type: { required: true, type: String },
			animationAfterMount: { type: Boolean, required: false, default: true },
			animationName: { type: String, required: false, default: 'fade-in' }
		},
		computed: {
			typeWithPrefix() {
				return `vue-icon-component-${this.type}`;
			}
		},
		components: {
			...svgsComponents,
			VueAnimationWrapper
		}
	};
</script>
