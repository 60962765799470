import { URLS }          from './config';
import axios             from '__js__/axios';
import reverse           from 'django-reverse';
import { AxiosResponse } from 'axios';
import { vm }            from './../../../';

export function favoriteCardDELETE<T = any>({ cardId }: { cardId: string }): Promise<AxiosResponse<T>> {
  return axios.delete<T>(
    reverse(URLS.MAP_FAVORITE_CARD, vm.$store.state.language, cardId)
  );
}
