import Vue        from "./../../vue";
import CxltToastr from "cxlt-vue2-toastr";

const icons = {
  success: [
    require("./assets/success_1.png"),
    require("./assets/success_2.png"),
    require("./assets/success_3.png"),
    require("./assets/success_4.png"),
    require("./assets/success_5.png")
  ],
  info: [
    require("./assets/info_1.png"),
    require("./assets/info_2.png"),
    require("./assets/info_3.png"),
    require("./assets/info_4.png"),
    require("./assets/info_5.png")
  ],
  warning: [
    require("./assets/warning_1.png"),
    require("./assets/warning_2.png"),
    require("./assets/warning_3.png"),
    require("./assets/warning_4.png"),
    require("./assets/warning_5.png")
  ],
  error: [
    require("./assets/error_1.png"),
    require("./assets/error_2.png"),
    require("./assets/error_3.png"),
    require("./assets/error_4.png"),
    require("./assets/error_5.png")
  ]
};

const config = {
  position: "top right",
  showDuration: 500,
  hideDuration: 1000,
  timeOut: 5000,
  delay: 0,
  closeButton: true,
  progressBar: false
};

Vue.use(CxltToastr, config);

const getHandler = (defConfig = {}) => {
  return {
    apply(target, thisArg, argumentsList) {
      const toastConfig = {
        ...defConfig,
        ...(argumentsList.length && typeof argumentsList[0] === "object" ? argumentsList[0] : {})
      };

      const toast = target(toastConfig);

      // if ( !toastConfig.icon ) {
      const originalShowToastr = toast.showToastr;

      toast.showToastr = () => {
        originalShowToastr(toast);

        toast.$nextTick(
          () => {
            toast.$el.classList.add(
              ...(toastConfig.classList ?? "").split(/\s/g).filter(Boolean)
            );

            if ( !toastConfig.icon ) {
              toast.$el.classList.add("toast--disable-icon");
            }

            if ( toastConfig.style ) {
              const style = Object.assign({}, toastConfig.style);

              Object.entries(style).forEach(([k, v]) => {
                const _v = toast.$el.style.getPropertyValue(k);
                toast.$el.style.setProperty(k, !!_v ? _v : v);
              });
            }
          }
        );
      };
      // }

      return toast;
    }
  };
};

Vue.prototype.$toast.success = new Proxy(
  Vue.prototype.$toast.success,
  getHandler({ icon: icons["success"][0] })
);

Vue.prototype.$toast.info = new Proxy(
  Vue.prototype.$toast.info,
  getHandler({ icon: icons["info"][0] })
);

Vue.prototype.$toast.warn = new Proxy(
  Vue.prototype.$toast.warn,
  getHandler({ icon: icons["warning"][0] }
  )
);

Vue.prototype.$toast.error = new Proxy(
  Vue.prototype.$toast.error,
  getHandler({ icon: icons["error"][4] })
);
