import * as MT from './_mutation-types';

export default {
	/* TILES */

	/**
	 * @param {Object.<string, any>} state
	 * @param {any[]} tiles
	 */
	[MT.TILES_SET](state, tiles) {
		state.tiles = tiles;
	},
	/**
	 * @param {Object.<string, any>} state
	 * @param {any[]} tiles
	 */
	[MT.TILES_ADD](state, tiles) {
		state.tiles.push(...tiles);
	},

	/* FILTER DIFFICULTY */
	/**
	 * @param {Object.<string, any>} state
	 * @param {any[]} difficulties
	 */
	[MT.FILTER_DIFFICULTY_SET](state, difficulties) {
		state.filters.difficulty.value = difficulties;
	},
	/**
	 * @param {Object.<string, any>} state
	 * @param {any[]} difficulties
	 */
	[MT.FILTER_DIFFICULTY_ADD](state, difficulties) {
		state.filters.difficulty.value.push(...difficulties);
	},
	/**
	 * @param {Object.<string, any>} state
	 * @param {boolean} isFetching
	 */
	[MT.FILTER_DIFFICULTY_IS_FETCHING_SET](state, isFetching) {
		state.filters.difficulty.isFetching = isFetching;
	},
	/**
	 * @param {Object.<string, any>} state
	 * @param {any[]} difficulties
	 */
	[MT.FILTER_DIFFICULTY_REMOVE](state, difficulties) {
		const _difficulty = state.filters.difficulty;

		_difficulty.value = _difficulty.value.filter(
			item => {
				return !difficulties.find(_item => _item.id === item.id);
			}
		);
	},

	/* FILTER LANGUAGE */
	/**
	 * @param {Object.<string, any>} state
	 * @param {any[]} languages
	 */
	[MT.FILTER_LANGUAGE_SET](state, languages) {
		state.filters.language.value = languages;
	},
	/**
	 * @param {Object.<string, any>} state
	 * @param {any[]} languages
	 */
	[MT.FILTER_LANGUAGE_ADD](state, languages) {
		state.filters.language.value.push(...languages);
	},
	/**
	 * @param {Object.<string, any>} state
	 * @param {boolean} isFetching
	 */
	[MT.FILTER_LANGUAGE_IS_FETCHING_SET](state, isFetching) {
		state.filters.language.isFetching = isFetching;
	},
	/**
	 * @param {Object.<string, any>} state
	 * @param {any[]} languages
	 */
	[MT.FILTER_LANGUAGE_REMOVE](state, languages) {
		const _language = state.filters.language;

		_language.value = _language.value.filter(
			item => {
				return !languages.find(_item => _item.id === item.id);
			}
		);
	},

	/* FILTER STATUS */
	/**
	 * @param {Object.<string, any>} state
	 * @param {any[]} statuses
	 */
	[MT.FILTER_STATUS_SET](state, statuses) {
		state.filters.status.value = statuses;
	},
	/**
	 * @param {Object.<string, any>} state
	 * @param {any[]} statuses
	 */
	[MT.FILTER_STATUS_ADD](state, statuses) {
		state.filters.status.value.push(...statuses);
	},
	/**
	 * @param {Object.<string, any>} state
	 * @param {boolean} isFetching
	 */
	[MT.FILTER_STATUS_IS_FETCHING_SET](state, isFetching) {
		state.filters.status.isFetching = isFetching;
	},
	/**
	 * @param {Object.<string, any>} state
	 * @param {any[]} statuses
	 */
	[MT.FILTER_STATUS_REMOVE](state, statuses) {
		const _status = state.filters.language;

		_status.value = _status.value.filter(
			item => {
				return !statuses.find(_item => _item.id === item.id);
			}
		);
	},

	/* FILTER TAG */

	/**
	 * @param {Object.<string, any>} state
	 * @param {any} tag
	 */
	[MT.FILTER_TAG_SET](state, tag) {
		state.filters.tag.value = tag;
	},

	/* FILTER TYPE */
	/**
	 * @param {Object.<string, any>} state
	 * @param {any} type
	 */
	[MT.FILTER_TYPE_SET](state, type) {
		state.filters.type.value = type;
	},

	/* FILTER SEARCH TERM */
	/**
	 * @param {Object.<string, any>} state
	 * @param {any} searchTerm
	 */
	[MT.FILTER_SEARCH_QUERY_SET](state, searchTerm) {
		state.filters.searchQuery.value = searchTerm;
	},

	/* FILTER PERSON */
	/**
	 * @param {Object.<string, any>} state
	 * @param {any} person
	 */
	[MT.FILTER_PERSON_SET](state, person) {
		state.filters.person.value = person;
	},

	/* ORDER BY */
	/**
	 * @param {Object.<string, any>} state
	 * @param {any} order
	 */
	[MT.ORDER_SET](state, order) {
		state.order.value = order;
	},

	/* NEXT URL */
	/**
	 * @param {Object.<string, any>} state
	 * @param {any} url
	 */
	[MT.NEXT_URL_SET](state, url) {
		state.nextUrl = url;
	},

	/* REQUEST TOKEN SOURCE */
	/**
	 * @param {Object.<string, any>} state
	 * @param {import("axios").CancelTokenSource} cancelTokenSource
	 */
	[MT.CANCEL_TOKEN_SOURCE_SET](state, cancelTokenSource) {
		state.cancelTokenSource = cancelTokenSource;
	},

	/* IS FETCHING */
	[MT.IS_FETCHING_SET](state, isFetching) {
		state.isFetching = isFetching;
	}
};
