export interface ICookieOptions {
	expires?: number | Date,
	path?: string
}

export interface ICookieMap {
	[cookieName: string]: string
}

export class Cookies {
	public static has(cookieName: string): boolean {
		return getParsedCookies()[cookieName] !== undefined;
	}

	public static get(cookieName?: string): string | undefined {
		return getParsedCookies()[cookieName];
	}

	public static getAll(): ICookieMap {
		return getParsedCookies();
	}

	public static set(cookieName: string, cookieValue: any, options: ICookieOptions = {}): void {
		if (Cookies.has(cookieName)) Cookies.delete(cookieName);
		window.document.cookie = [
			`${getValueStatement(cookieName, cookieValue)}`,
			`${getPathStatement(options.path)}`,
			`${getExpiresStatement(options.expires)}`
		].join(' ').trim();
	}

	public static delete(cookieName: string, path: string = '/'): string | undefined {
		const cookie = Cookies.get(cookieName);

		window.document.cookie = [
			`${getValueStatement(cookieName, ``)}`,
			`${getPathStatement(path)}`,
			`${getExpiresStatement(new Date(null))}`
		].join(' ').trim();

		return cookie;
	}
}

function getValueStatement(key: string, value: string): string {
	return `${key}=${encodeURI(value)};`;
}

function getPathStatement(path: string): string {
	if (!path) return `path=/;`;
	return `path=${path};`;
}

function getExpiresStatement(value: number | Date): string {
	if (typeof value === 'number') return `expires=${new Date(Date.now() + value).toUTCString()};`;
	if (value instanceof Date) return `expires=${value.toUTCString()};`;
	return ``;
}

function getParsedCookies(): ICookieMap {
	return !window.document.cookie ? {} :
		window.document.cookie
			.split(';')
			.reduce((accumulator, current) => {
				const [key, value] = current.split('=');
				accumulator[key.trim()] = decodeURI(value);
				return accumulator;
			}, {});
}
