import axios from '__js__/axios';
import reverse from 'django-reverse';
import { AxiosResponse } from 'axios';
import { URLS } from './URLS';
import { ITile } from './../types/tile.interface';
import { vm } from './../../../../';

export default async ({ mapTranslateId }: { mapTranslateId: string }): Promise<AxiosResponse<ITile>> => {
	return await axios.get<ITile>(reverse(URLS.TILE, vm.$store.state.language, mapTranslateId));
}

