import URLS from './URLS';
import axios from '__js__/axios';
import { AxiosResponse } from 'axios';
import reverse from 'django-reverse';
import { vm } from './../../../../';

interface IData {
	mapTranslateId: string;
}

interface IResponseData {
	[key: string]: any
}

export default async ({ mapTranslateId }: IData): Promise<AxiosResponse<IResponseData>> => {
	return await axios.patch(reverse(URLS.LOCK, vm.$store.state.language, mapTranslateId));
}
