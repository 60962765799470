const defaultOptions = {
	target: ``,
	searchParams: {},
	features: {
		width: 600,
		height: 400
	}
};

export default {
	methods: {
		shareFacebook(options) {
			const opt = { ...defaultOptions, ...options };

			window.open(
				getUrl(opt),
				undefined,
				getFeatures(opt)
			);
		}
	}
};

function getUrl({ searchParams, target }) {
	let url = new window.URL(`https://www.facebook.com/sharer/sharer.php`);

	if (target)
		url.searchParams.set('u', target);

	for (const [key, value] of Object.entries(searchParams))
		url.searchParams.set(key, value);
	console.log(url.toString());
	return url.toString();
}

function getFeatures({ features }) {
	return Object.entries(features).map(([key, value]) => `${key}=${value}`).join(',');
}
