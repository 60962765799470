import axios                          from '__js__/axios';
import reverse                        from 'django-reverse';
import { SEARCH_TYPES, URLS }         from './../../config';
import { AxiosResponse, CancelToken } from 'axios';
import { vm }                         from './../../../../';

export interface IData {
	type: SEARCH_TYPES;
	tag: string;
	search: string;
	ordering: string;
	statuses?: string[];
	difficulties?: string[];
	mapLanguages?: string[];
	url?: string;
	personId?: string;
	cancelToken?: CancelToken;
}

interface IResponseData {
	next: string;
	previous: string;
	count: number;
	results: Array<any>;
}

const resolveUrl = (options: IData): string => {
	const { personId, type, tag } = options;
	const lang = vm.$store.state.language;

	if ( personId ) {
		return reverse(URLS.TILE_PERSON, lang, personId, type);
	}

	if ( !tag ) {
		return reverse(URLS.TILES, lang, type);
	}

	return `/map-api/tiles-tag/${ tag }`;
};

export const fetchData = async (options: IData): Promise<AxiosResponse<IResponseData>> => {
	const {
		ordering,
		search,
		statuses,
		difficulties,
		mapLanguages,
		url,
		cancelToken
	} = options;

	return await axios.get(
		url ?? resolveUrl(options),
		{
			cancelToken,
			params: !url ?
				{
					ordering,
					search,
					statuses,
					difficulties,
					mapLanguages
				} :
				undefined
		}
	);
};
