import Vue from 'vue';
import EVENT_BUS, { EVENT_BUS_TYPES } from '__event-bus__';
import axios from '__js__/axios';

import VueIcon from '__components__/Icon/App';
import VuePreloader from '__components__/Preloader/App';
import moment from 'moment';

// Global Components
Vue.component('vue-icon', VueIcon);
Vue.component('vue-preloader', VuePreloader);

Vue.use(require('vue-cookies'))

Vue.filter('formatDate', function(value, format = 'MMMM Do YYYY', locale = 'cs') {
	if (value) {
		moment.locale(locale);
		return moment(String(value)).format(format);
	}
});

Vue.filter('capitalize', function(value) {
	if (!value) return '';
	value = value.toString();
	return value.charAt(0).toUpperCase() + value.slice(1);
});


Object.defineProperties(Vue.prototype, {
	/**
	 * Application language getter and setter
	 */
	$language: {
		enumerable: false,
		configurable: false,
		get() {
			return this.$i18n.locale;
		},
		set(language) {
			this.$i18n.locale = language;
			this.$store.commit(
				this.$store.VUEX_GLOBAL.MUTATION_TYPES.LANGUAGE_SET,
				language
			);
		}
	},

	/**
	 * Asynchronous api to make http requests. Call this.$http.get(url).then((data) => ... )
	 */
	$http: {
		enumerable: false,
		configurable: false,
		writable: false,
		value: axios
	},

	/**
	 * Global Event bus
	 */
	$eventBus: {
		enumerable: false,
		configurable: false,
		writable: false,
		value: {
			/**
			 * @param {string} event
			 * @param {Function} callback
			 * @returns {Vue | any}
			 */
			on: (event, callback) => EVENT_BUS.$on(event, callback),
			/**
			 * @param {string} event
			 * @param {Function} callback
			 * @returns {Vue | any}
			 */
			once: (event, callback) => EVENT_BUS.$once(event, callback),
			/**
			 * @param {string} event
			 * @param {Function} callback
			 * @returns {Vue | any}
			 */
			emit: (event, callback) => EVENT_BUS.$emit(event, callback),
			/**
			 * @param {string} event
			 * @param {Function} callback
			 * @returns {Vue | any}
			 */
			off: (event, callback) => EVENT_BUS.$off(event, callback),

			type: EVENT_BUS_TYPES
		}
	}
});

export default Vue;
