import axios             from '__js__/axios';
import reverse           from 'django-reverse';
import { AxiosResponse } from 'axios';
import { URLS }          from './URLS';
import { vm }            from './../../../../';

export const reportInappropriateContent = ({ mapTranslateId }: { mapTranslateId: string }): Promise<AxiosResponse<any>> => {
  const url = reverse(
    URLS.REPORT_INAPPROPRIATE_CONTENT,
    vm.$store.state.language,
    mapTranslateId
  );

  return axios.post(url);
};
