<template>
	<transition :name="animationName" :appear="appear">
		<slot></slot>
	</transition>
</template>

<script lang="js">
	export default {
		name: 'vue-animation-wrapper',

		props: {
			// Start animation after the element is inserted to the DOM - loading PAGE
			appear: { type: Boolean, required: false, default: false },
			animationName: { type: String, required: false, default: 'fade-in' }
		}
	};
</script>
