import axios                          from "__js__/axios/index";
import URLS                           from "./URLS";
import reverse                        from "django-reverse";
import * as DataTranslator            from "./../controllers/DataTranslator";
import { AxiosResponse, CancelToken } from "axios";
import { vm }                         from "../../../../";

interface Options {
  cancelToken?: CancelToken;
}

export default async (data: DataTranslator.Request.Save.IData, options?: Options): Promise<AxiosResponse> => {
  return await axios.put(
    reverse(URLS.MAP_UPDATE, vm.$store.state.language, data.id),
    data,
    {
      cancelToken: options.cancelToken
    }
  );
};
