import { getMembershipProperty } from '__vuex__/helpers';

export default {
  personRights: ({ tile }) =>
    tile?.person_rights ?? null,

  isSomeSectionOpen: state =>
    Object.values(state.sections).some(({ isOpen }) => isOpen),

  isSomeSectionActive: state =>
    Object.values(state.sections).some(({ isActive }) => isActive),

  maxMapCards: (state, getters, rootState) =>
    getMembershipProperty(rootState, 'max_map_cards')?.config?.value ?? Number.MAX_SAFE_INTEGER,

  maxPrivateMapTranslates: (state, getters, rootState) =>
    getMembershipProperty(rootState, 'max_private_map_translates')?.config?.value ?? Number.MAX_SAFE_INTEGER,

  maxPublicMapTranslates: (state, getters, rootState) =>
    getMembershipProperty(rootState, 'max_public_map_translates')?.config?.value ?? Number.MAX_SAFE_INTEGER,

  isAllowedEditEditorBackground: (state, getters, rootState) =>
    !!getMembershipProperty(rootState, 'edit_editor_background'),

  isAllowedMapInterconnection: (state, getters, rootState) =>
    !!getMembershipProperty(rootState, 'map_interconnect') ?? false,

  isAllowedComputerPronunciation: (state, getters, rootState) =>
    !!(getMembershipProperty(rootState, 'computer_pronunciation') ?? false),

  isAllowedExportPdf: (state, getters, rootState) =>
    !!(getMembershipProperty(rootState, 'export_pdf')) ?? false,

  printAreaPaperSize: (state) =>
    state.printArea.paperSize,

  printAreaPaperOrientation: (state) =>
    state.printArea.orientation,

  printAreaVisible: (state) =>
    state.printArea.visible,

  printAreaIsExtendedPrintConfiguration: (state) => {
    return !(state.printArea.paperSize === 'a4' && state.printArea.orientation === 'landscape');
  }
};
