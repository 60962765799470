export default {
  tile: null,
  canvas: {
    scale: {
      // step: 0.1,
      value: 1,
      // max: 2,
      // min: 0.25
    }
  },
  status: {
    id: null,
    identifier: null,
    description: null
  },
  isSomePopupOpened: false,
  header: {
    visible: true
  },
  sections: {
    settings: {
      identifier: "settings",
      isActive: false,
      isOpen: false
    },
    publish: {
      identifier: "publish",
      isActive: false,
      isOpen: false
    },
    mapInterconnection: {
      identifier: "mapInterconnection",
      isActive: false,
      isOpen: false
    }
  },
  printArea: {
    orientation: "landscape",
    paperSize: "a4",
    visible: false,
    disabled: false,
    configurable: false
  }
};
