import mapCreate              from './_create';
import mapDelete              from './_delete';
import mapDetail              from './_detail';
import { favoriteCardPOST }   from './_favoriteCardPOST';
import { favoriteCardsGET }   from './_favoriteCardsGET';
import { favoriteCardDELETE } from './_favoriteCardDELETE';

export default {
  mapCreate,
  mapDelete,
  mapDetail,
  favoriteCardsGET,
  favoriteCardDELETE,
  favoriteCardPOST
};
