import axios                          from '__js__/axios';
import reverse                        from 'django-reverse';
import { AxiosResponse, CancelToken } from 'axios';
import { URLS }                       from './URLS';
import { IMapTranslate }              from '__components__/editor/EditorView/types/mapTranslate.interface';
import { vm }                         from './../../../../';

export default async (
  { mapTranslateId, name }: { mapTranslateId: string, name: string },
  options?: { cancelToken?: CancelToken }
): Promise<AxiosResponse<IMapTranslate>> => {
  return await axios.patch<IMapTranslate>(
    reverse(URLS.MAP_UPDATE_NAME, vm.$store.state.language, mapTranslateId),
    { name },
    {
      cancelToken: options?.cancelToken
    }
  );
}

